body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.label {
    padding: 3px 6px 3px;
    font-size: 11px;
    border-radius: 50px;
    text-transform: capitalize;
    font-weight: 600;
    color: #ffffff;
}

.label.label-info {
    background: #f1a1c7;
}

.label.label-primary {
    background: #4e9de6;
}

.label.label-success {
    background: #2ecd99;
}

.label.label-danger {
    background: #ed6f56;
}

.label.label-warning {
    background: #f0c541;
}

.image-sale img {
    width: 36px;
    height: 36px;
    border-radius: 100%;
}

.action-mail .btn-default {
    background: gainsboro;
    border: solid 1px gainsboro;
}

.table-invoice #subDescription {
    height: 40px;
}

.custom-discount .form-group {
    margin: 0;
    display: inline-block;
    text-align: left;
}

.custom-discount .form-group .form-check {
    display: inline-block;
    margin: 0;
}

.custom-discount .form-group .form-check .form-check-label {
    cursor: pointer;
}

.billed-to .main-img-user::after {
    display: none !important;
}

.billed-to .main-img-user {
    margin-bottom: 8px;
}

.info-transaction {
    padding: 20px;
    border: 2px solid #efefed;
    border-top-left-radius: 35px;
    border-bottom-right-radius: 35px;
}

.detail-info {
    background-color: #efefed;
    padding: 15px;
    border-radius: 8px;
}

.body-include-itinerary ul {
    padding: 0;
    margin: 0;
}

.body-include-itinerary ul li {
    list-style: inside;
}

.title-text-label {
    font-size: 14px;
    color: #CC0000;
    font-weight: bold;
}

.bg-tr {
    background-color: #c95e0d;
}

.body-content-itinerary .content-block {
    margin-bottom: 1.5rem;
}

.body-content-itinerary .content-block p {
    margin: 0;
}

.itinerary-action .btn-block {
    padding: 3px 10px;
}

iframe {
    display: none !important;
}