* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

ul {
    list-style: none;
}

li {
    display: inline-block;
}

@media only screen and (max-width: 665px) {
    body {
        width: 100%;
        margin: 0;
    }
}

.font-2 {
    font-family: "Montserrat", sans-serif;
}

a {
    text-decoration: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.main-logo {
    width: calc(100px + (110 - 100) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
    margin: 0;
}

.header td {
    padding: 16px calc(12px + (26 - 12) * ((100vw - 320px) / (1920 - 320)));
    width: 0;
}

.menu {
    width: 100%;
}

.menu li {
    margin-left: 20px;
}

@media only screen and (max-width: 450px) {
    .header {
        display: block;
        text-align: center;
        padding: 16px 0;
    }
    .header td {
        display: block;
        text-align: center;
        padding-top: 0;
        padding-bottom: 0;
    }
    .menu {
        margin-top: 6px;
    }
    .menu li {
        margin-left: calc(5px + (20 - 5) * ((100vw - 320px) / (1920 - 320)));
        margin-right: calc(5px + (20 - 5) * ((100vw - 320px) / (1920 - 320)));
    }
}

.menu li a {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #252525;
    text-transform: capitalize;
}

.button-solid {
    font-weight: bold;
    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    line-height: calc(22px + (27 - 22) * ((100vw - 320px) / (1920 - 320)));
    display: inline-block;
    color: #ffffff;
    background: #ff4c3b;
    border-radius: 6px;
    padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320))) calc(25px + (43 - 25) * ((100vw - 320px) / (1920 - 320)));
}

table .banner {
    position: relative;
    border: 0;
    padding: 0;
    background-color: transparent;
    margin: 0;
}

.banner img {
    margin-bottom: -6px;
}

.section-t {
    margin-top: calc(25px + (25 - 32) * ((100vw - 320px) / (1920 - 320)));
    display: block;
    text-align: center;
}

.heading-1 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
}

.pera {
    font-size: 14px;
    line-height: 23px;
    text-align: left;
    color: #191919;
    margin-bottom: 5px;
}

.product-card {
    border-collapse: separate;
    border-spacing: calc(5px + (22 - 5) * ((100vw - 320px) / (1920 - 320)));
    margin-top: calc(12px + (0 - 12) * ((100vw - 320px) / (1920 - 320)));
}

.product-card a {
    display: block;
    text-align: center;
}

.product-card a img {
    width: 100%;
}

.product-card a h5 {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #252525;
    text-align: center;
    min-height: 30px;
    margin-top: 5px;
    margin-bottom: 0;
}

.product-card a .price {
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    color: #ff4c3b;
}

.product-card a .buy-link {
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    color: #939393;
    display: block;
}

.footer {
    position: relative;
    width: 100%;
}

@media only screen and (max-width: 345px) {
    .footer-content {
        padding-left: 15px;
        padding-right: 15px;
    }
    .footer-content>table {
        width: 100% !important;
    }
}

.footer-content p,
.footer-content a {
    font-weight: 800;
    font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.5px;
}

.footer-content p {
    color: #e4e4e4;
    margin-top: 15px;
}

.footer-content .unsubscribe {
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #ff4c3b;
    display: inline-block;
    margin-top: calc(14px + (21 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.footer-content .social td {
    width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    height: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    display: inline-block;
    margin: 0 calc(7px + (10 - 7) * ((100vw - 320px) / (1920 - 320)));
}

.footer-content td img {
    width: 100%;
}

.link-detail {
    color: #087cce;
    text-decoration: underline;
    font-weight: 700;
}

.image-sale .col-sm button {
    display: flex;
    float: left;
}