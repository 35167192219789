.main-profile-menu .profile-user.btn-primary {
    background-color: transparent;
    border-color: transparent
}

.main-profile-menu .profile-user.btn-primary:active {
    background-color: transparent;
    border-color: transparent
}

.main-profile-menu .dropdown-toggle.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: inherit;
}

.main-profile-menu.show .dropdown-menu {
    inset: 0px auto auto -180px !important;
}

.main-header-message .dropdown-toggle.btn-primary {
    background-color: transparent;
    border-color: transparent
}

.main-header-message .dropdown-toggle.btn-primary:active {
    background-color: transparent;
    border-color: transparent
}

.main-header-message .dropdown-toggle.btn-primary:active:focus {
    box-shadow: inherit;
}

.main-header-message.show .dropdown-menu {
    inset: 6px auto auto -245px !important;
}

.main-header-notification .dropdown-toggle.btn-primary {
    background-color: transparent;
    border-color: transparent
}

.main-header-notification .dropdown-toggle.btn-primary:active {
    background-color: transparent;
    border-color: transparent
}

.main-header-notification .dropdown-toggle.btn-primary:active:focus {
    box-shadow: inherit;
}

.main-header-notification.show .dropdown-menu {
    top: 52px;
}

.full-screen.fullscreen-button .full-screen-link svg {
    position: absolute;
    top: 10px;
    right: 10px;
}

.dropdown-toggle::after {
    display: none;
}

.main-header-message.right-toggle .nav-link svg {
    position: absolute;
    top: 10px;
    left: 0;
}

.bg-font {
    color: #efefef;
    font-weight: bold;
}

.bg-blue {
    background-color: #3a87ad;
}

.bg-green {
    background-color: #468847;
}

.bg-red {
    background-color: #580925;
}

.bg-pending {
    background-color: #f89406;
}

.bg-canceled {
    background-color: #b94a48;
}

.bg-transferred {
    background-color: red;
}

.bg-inspection {
    background-color: #ee1289;
}

.bg-upTo {
    background-color: #63259f;
}

.date-control {
    display: flex;
    flex-wrap: wrap;
}

.date-control-button-item button {
    margin-top: 1.8rem;
}

.table-info-cruise-title {
    font-weight: 700;
    color: #242f48;
    font-size: 20px;
    text-transform: uppercase;
}

.w-220 {
    width: 320px;
}

.w-150 {
    width: 200px;
}

.custom-control-label {
    cursor: pointer;
}

.hideclassName {
    display: none;
}

.ck-editor__editable.ck-rounded-corners {
    min-height: 300px;
}

.modal-full {
    max-width: 80%;
}

.action-group .template-holidays {
    width: 300px;
}

.main-mail-options .ckbox {
    cursor: pointer;
}

.main-mail-checkbox .ckbox {
    cursor: pointer;
}

.subject-mail>.form-control {
    min-width: 300px;
}

.card-header>.choose-country {
    display: inline-block;
    width: 100%;
    text-align: right;
}

.card-header>.choose-country .form-group {
    display: inline-block;
    margin-right: 15px;
}

strong.tx-slow {
    background-color: #fbbc0b;
    padding: 5px;
    border-radius: 5px;
    color: #ffffff;
    text-transform: capitalize;
}

strong.tx-medium {
    background-color: #00b9ff;
    padding: 5px;
    border-radius: 5px;
    color: #ffffff;
    text-transform: capitalize;
}

strong.tx-high {
    background-color: #ee335e;
    padding: 5px;
    border-radius: 5px;
    color: #ffffff;
    text-transform: capitalize;
}

.booking-tour.table-responsive {
    overflow-x: scroll;
}

.item-status {
    display: inline-block;
    margin: 0 5px;
    background-color: #3a87ad;
    padding: 5px;
    border-radius: 5px;
}

.item-status a {
    color: #ffffff;
    font-size: 14px;
}

.item-status.booking-run {
    background-color: #ee1289;
}

.popover {
    max-width: 350px;
    width: 100%;
    min-height: 150px;
}

.popover .arrow {
    width: 1rem;
    height: .5rem;
    margin: 0 .3rem;
}

.bs-popover-auto[x-placement^=bottom]>.arrow,
.bs-popover-bottom>.arrow {
    top: calc(-.5rem + -1px);
}

.bs-popover-auto[x-placement^=bottom]>.arrow:before,
.bs-popover-bottom>.arrow:before {
    top: 0;
    border-width: 0 .5rem .5rem;
    border-bottom-color: rgba(0, 0, 0, .25);
}

.popover .arrow:after,
.popover .arrow:before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-popover-auto[x-placement^=bottom]>.arrow:after,
.bs-popover-bottom>.arrow:after {
    top: 1px;
    border-width: 0 .5rem .5rem;
    border-bottom-color: #fff;
}

.message {
    border-radius: 4px;
    font-size: 1rem;
}

.message.is-info .message-body {
    border-radius: 4px;
    padding: 1.25em 1.5em;
    border: solid #209cee;
    border-width: 0 0 0 4px;
    color: #12537e;
}

.message.popover-body {
    padding: 0;
}

.is-info {
    background-color: #f6fbfe;
}

.popover-body label {
    font-size: 14px;
}

.th-style {
    line-height: 45px!important;
}